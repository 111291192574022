.preloader{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      background: rgb(255 255 255 / 77%);
      z-index: 9000;
      backdrop-filter: blur(18px);
  }
  .preloSvg{
    width:150px;
  }
  .squiggle {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1600;
    animation: draw 2.3s linear infinite alternate;
  }
  
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  